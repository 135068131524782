import { selectOrganizationSchema } from '@repo/db/schema'
import { z } from 'zod'
import type { searchOrgs } from './searchOrgs.server'

export type OrgSearchResult = Awaited<ReturnType<typeof searchOrgs>>[number]

export const SwitchOrganizationSchema = z.object({
  intent: z.literal('switchOrganization'),
  orgId: selectOrganizationSchema.shape.id,
  portfolioMode: z
    .enum(['true', 'false'])
    .default('false')
    .transform((v) => v === 'true'),
  sourceUrl: z.string()
})

export const SearchOrgsSchema = z.object({
  intent: z.literal('searchOrgs'),
  query: z.string().trim()
})
